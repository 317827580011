<template>
  <div class="text-center">
    <div class="mb-2"><b-icon :icon="icon" font-scale="3"></b-icon></div>
    <small>{{ notes }}</small>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: ['notes', 'icon'],
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';
h3 {
  color: white;
}
</style>
