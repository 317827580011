<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <Header title="已完成的和解書" backto="/menu" />
        <EmptyState v-show="contracts.length === 0" notes="沒有已完成的和解書" icon="inbox" />
        <div v-for="(item, key) in contracts" :key="key">
          <ListItem
            :title="item.accidentRecord.city + item.accidentRecord.area"
            :des="format(parseISO(item.createdTime), 'yyyy/MM/dd')"
            :externalLink="true"
            :target="`${s3_host}/${item.signedPdf}?openExternalBrowser=1`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseISO, format } from 'date-fns';
import { contract } from '@/mixins';
import Header from '@/components/Header.vue';
import ListItem from '@/components/ListItem.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  mixins: [contract],
  computed: {
    ...mapState({
      contracts: (state) => state.contracts.contracts,
    }),
  },
  components: {
    Header,
    ListItem,
    EmptyState,
  },
  data() {
    return {
      s3_host: process.env.VUE_APP_S3_HOST,
    };
  },
  methods: {
    parseISO,
    format,
  },
  mounted() {
    this.fetchContracts();
  },
};
</script>

<style></style>
