<template>
  <a v-if="externalLink" :href="target" target="_blank">
    <div class="listItem">
      <small class="des">{{ des }}</small>
      <div class="title">{{ title }}</div>
    </div>
  </a>
  <router-link v-else :to="{ path: target }">
    <div class="listItem">
      <small class="des">{{ des }}</small>
      <div class="title">{{ title }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['title', 'des', 'target', 'externalLink'],
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';

.listItem {
  background-color: @dark-grey;
  text-decoration: none;
  display: block;
  margin-bottom: 12px;
  border-radius: 6px;
  padding: 11px 21px;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: darken(@dark-grey, 10%);
  }
}

.title {
  margin-top: 3px;
  color: white;
}

.des {
  color: gray;
}
</style>
